<template>
  <div>
    <header class="mui-bar mui-bar-nav">
      <a
        class="mui-action-back mui-icon mui-icon-left-nav mui-pull-left"
        @click="goBack"
      ></a>
      <h1 class="mui-title">{{ currentName }}</h1>
    </header>
    <div class="mui-content">
      <van-collapse v-if="isHaveChild" v-model="m_activeNames" accordion :border="true">
        <van-collapse-item
          :name="series_item.ID"
          v-for="(series_item, index) in listInfo"
          :key="'catalog' + index"
          :class="index % 2 == 0 ? 'bg-gray' : 'bg-white'"
          @click="collapseItemClick(series_item.List?true:false)"
        >
          <template #title>
            <h4>{{ series_item.Name }}</h4>
            <h5>{{ series_item.NameEn }}</h5>
          </template>
          <template #right-icon v-if="!series_item.List">
              <van-icon name="" ></van-icon>
          </template>
          <div v-for="node in series_item.List" :key="node.ID" style>
            <van-cell
              style="padding: 10px 16px"
              is-link
              size="large"
              @click="toDetail(series_item.ListID,node.ID)"
            >
              <template #title>
                <h5 style="color: #000000">{{ node.Name }}</h5>
                <h6 style="color: #aaaaaa">{{ node.NameEn }}</h6>
              </template>
              <template #right-icon>
                <van-icon
                  style="position: absolute; right: 15px; top: 20px"
                  name="arrow"
                  size="18"
                />
              </template>
            </van-cell>
            <!-- <div style="width: 100%; height: 0px; background: #eee"></div> -->
          </div>
        </van-collapse-item>
      </van-collapse>
          <div v-else v-for="list in listInfo" :key="list.ID" style>
            <van-cell
              style="padding: 10px 16px"
              is-link
              size="large"
              @click="toDetail(list.ListID,list.ID)"
            >
              <template #title>
                <h5 style="color: #000000">{{ list.Name }}</h5>
                <h6 style="color: #aaaaaa">{{ list.NameEn }}</h6>
              </template>
              <template #right-icon>
                <van-icon
                  style="position: absolute; right: 15px; top: 20px"
                  name="arrow"
                  size="18"
                />
              </template>
            </van-cell>
            <div style="width: 100%; height: 0px; background: #eee"></div>
          </div>

    </div>
  </div>
</template>

<script>
import Data from "../data/listJson";
export default {
  data() {
    return {
      listInfo: [],
      currentName: "列表",
      m_activeNames: "1",
      isHaveChild:true,//是否含有子项
    };
  },
  mounted() {
    // console.log(this.$route.query, 16);
    let list = [];
    Data.ListJson.forEach((item)=>{
      if (this.$route.query.ID == item.ID) {
        this.currentName = item.Name
      }
    })
    Data.InfoListJson.forEach((ele) => {
      if (this.$route.query.ID == ele.ListID) {
        if(ele.List != undefined){
          this.isHaveChild = true
          list.push(ele);
        }else {
          this.isHaveChild = false
          list.push(ele);
        }
      }
    });
    document.title = this.currentName
    this.listInfo = list;
    // console.log(this.listInfo, 47);
  },
  methods: {
    toDetail(groupID,nodeID) {
        // let pdfFileContent = groupItem.ListID + '/' + groupItem.NameEn + '/' + nodeItem.ID + "_" + nodeItem.NameEn
        // console.log(groupItem,nodeItem,73)
        this.$router.push({
            path:"/cn/detail",
            query:{
                GID:groupID,
                NID:nodeID,
            }
        })
        // console.log(pdfFileContent,75)
    },
    //检验是否有层级
    collapseItemClick(status){
      if(status){
        return
      }
   this.$dialog.alert({
      message: '暂未添加数据',
    });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
.van-cell {
  font-size: 16px;
  position: relative;
}
.van-cell__title {
  color: #528abe;
  text-align: start;
}
.van-cell__right-icon {
  color: #528abe;
}
.van-collapse-item__content {
  padding: unset;
}
.van-cell--large .van-cell__title {
  font-size: 14px;
}
/* .bg-gray {
  background-color: #f1f2f3;
} */
.bg-white {
  background-color: #ffffff;
}
.van-collapse-item__title {
  background-color: transparent;
}
</style>