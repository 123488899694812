<template>
  <div>
    <header class="mui-bar mui-bar-nav">
      <h1 class="mui-title" style="margin-left: -10px">
        <img
          src="../assets/img/logo.png"
          style="position: relative; top: 4px; height: 36px"
        />
      </h1>
    </header>
    <div class="mui-content">
      <div class="mui-scroll-wrapper">
          <div class="mui-scroll" style="height: 100%;padding-top: 44px; background-color: white; overflow: auto;">
                <div class="img_div_list" id="img_div_list" v-for="(item,index) in menuList" :key="item.ID">
                    <div class="mui-col-xs-12 img_info_div" @click="toListInfo(item)" v-if="index % 2 == 0">
                      <div class="mui-col-xs-6 info_div info_div_left">
                        <h3>{{item.Name}}</h3>
                        <h4>{{item.NameEn}}</h4>
                      </div>
                      <div class="mui-col-xs-6 img_div ">
                         <img class="mui-media-object" :src="require('../assets/img/'+ item.imgUrl)" />
                      </div>
                    </div>

                    <div class="mui-col-xs-12 img_info_div" @click="toListInfo(item)" v-else>
                      <div class="mui-col-xs-6 img_div">
                        <img class="mui-media-object" :src="require('../assets/img/'+ item.imgUrl)" />
                      </div>
                      <div class="mui-col-xs-6 info_div info_div_right">
                         <h3>{{item.Name}}</h3>
                        <h4>{{item.NameEn}}</h4>
                      </div>
                    </div>
                </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import Data from "../data/listJson"
export default {
  data() {
    return {
      menuList: [],
    };
  },
  mounted() {
    // console.log(Data.ListJson,40)
    this.menuList = Data.ListJson
  },
  methods:{
    toListInfo(item){
      let ID = item.ID
      this.$router.push({
        path:"/cn/list",
        query:{ID:ID}
      })
    }
  },
};
</script>

<style scoped>
.mui-bar {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 1px solid #ccc;
  background-color: #002258;
}

.zmLabel {
  font-weight: 600;
}

.liSeries {
  padding-top: 5px;
  padding-bottom: 2px;
  padding-right: 1px;
}
h3 {
  margin-top: 40px;
  color: #00508e;
  font-size: 20px;
  text-shadow: unset;
}
h4,
.mui-h4 {
  font-size: 16px;
  color: #949292;
  font-weight: 800;
}
.divSeriesNameContainter {
  padding-left: 80px;
}

.img_div_list img {
  width: 100%;
  height: 120px;
}

.img_div,
.info_div {
  float: left;
}

.info_div,
.img_div {
  height: 120px;
}

.info_div_left:before {
  content: "";
  display: block;
  border-width: 8px 10px;
  position: absolute;
  right: -19px;
  border-radius: 2px;
  border-style: solid dashed dashed;
  border-color: transparent transparent transparent white;
  font-size: 0;
  line-height: 0;
  z-index: 1;
  bottom: 15px;
}

.info_div_right:before {
  content: "";
  display: block;
  border-width: 8px 10px;
  position: absolute;
  left: -19px;
  border-radius: 2px;
  border-style: solid dashed dashed;
  border-color: transparent white transparent transparent;
  font-size: 0;
  line-height: 0;
  z-index: 1;
  bottom: 15px;
}

.img_info_div {
  clear: both;
  height: 120px;
}
</style>