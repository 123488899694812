<template>
  <div style="width: 100%; height: 100%">
    <header class="mui-bar mui-bar-nav">
      <a
        class="mui-action-back mui-icon mui-icon-left-nav mui-pull-left"
        style="color: #6396c2"
        @click="goBack"
      ></a>
      <h1 class="mui-title">{{ currenPageName }}</h1>
    </header>
    <div class="mui-content" style="height: 100%">
      <!-- pdf容器 -->
      <div id="Demo"></div>

      <ul
        id="navs"
        :class="isExpandMenu ? 'active' : ''"
        @click="isExpandMenu = !isExpandMenu"
      >
        <li :class="isExpandMenu ? 'downLoad_style' : ''" @click="downLoadFile">
          <a>
            <img src="../assets/img/download.png" alt="" />
          </a>
        </li>
        <li :class="isExpandMenu ? 'english_style' : ''">
          <a>
            <img src="../assets/img/english.png" alt="" />
          </a>
        </li>
        <li :class="isExpandMenu ? 'de_style' : ''">
          <a>
            <img src="../assets/img/de.png" alt="" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Data from "../data/listJson";
import Pdfh5 from "pdfh5";
import "pdfh5/css/pdfh5.css";
import "../assets/css/navs_Menu.css";
export default {
  data() {
    return {
      currenPageName: "文档",
      isExpandMenu: false,
      currentLangType: "EN",
      downloadFileUrl: "",
    };
  },
  mounted() {
    let _this = this;
    let pageQuery = this.$route.query;
    // console.log(pageQuery,51)
    let fileUrl = "static/Files/";
    let pdfUrl = "";
    Data.InfoListJson.forEach((Element) => {
      if (Element.ListID == pageQuery.GID) {
        if (Element.List != undefined) {
          Element.List.forEach((node) => {
            if (node.ID == pageQuery.NID) {
              // console.log(111)
              pdfUrl =
                Element.ListID + "/" + node.ID + "_" + _this.currentLangType;
              _this.currenPageName = node.Name;
            }
          });
        } else {
          if (Element.ID == pageQuery.NID) {
            pdfUrl =
              Element.ListID + "/" + Element.ID + "_" + _this.currentLangType;
            _this.currenPageName = Element.Name;
          }
        }
      }
    });
    document.title = _this.currenPageName;
    let pdfSrc = fileUrl + pdfUrl + ".pdf";
    // console.log(pdfSrc, 33);

    if (pdfSrc == "" || pdfSrc == null) {
      return;
    }
    _this.downloadFileUrl = pdfSrc;
    //初始化之前清空一下div内容
    document.getElementById("Demo").innerHTML = "";
    //实例化
    let pdfh5 = new Pdfh5("#Demo", {
      pdfurl: pdfSrc,
      lazy: true,
    });
    pdfh5.on("init", function () {
      console.log("开始初始化");
    });
    // pdfh5.on("render", function (currentNum, time, currentPageDom) {
    //   console.log(currentNum, time, currentPageDom, 63);
    // });
  },
  methods: {
    downLoadFile() {
      this.getDownloadUrl(this.downloadFileUrl, "文档");
    },
    getDownloadUrl(url, filename) {
      return fetch(url).then((res) =>
        res.blob().then((blob) => {
          let a = document.createElement("a");
          let url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
        })
      )
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 0px;
}
a {
  color: #42b983;
}
.pdfPageTwo {
  height: calc(100% - 0px);
  overflow: auto;
}
.downLoad_style {
  transition-delay: 0ms;
  transform: translate(-65px, 0px);
}
.english_style {
  transition-delay: 50ms;
  transform: translate(-45.9619px, -45.9619px);
}
.de_style {
  transition-delay: 100ms;
  transform: translate(-3.9801e-15px, -65px);
}
</style>
