let data = {
    ListJson: [{
        ID: "01",
        Name: "高压元件",
        NameEn: "VFT",
        btnColor: "#e72b76",
        color: "#fff",
        imgUrl: "01.png",
        List: [

        ]
    }, {
        ID: "02",
        Name: "切割头",
        NameEn: "Cutting Heads",
        btnColor: "#009fd7",
        color: "#fff",
        imgUrl: "02.png",
    }, {
        ID: "03",
        Name: "送砂系统",
        NameEn: "Abrasive System",
        btnColor: "#8dbe3b",
        color: "#fff",
        imgUrl: "03.png",
    }, {
        ID: "04",
        Name: "机械工具",
        NameEn: "Machine Tools",
        btnColor: "#fff114",
        color: "#3e3e3d",
        imgUrl: "04.png",
    }, {
        ID: "05",
        Name: "消耗品",
        NameEn: "Consumable Parts",
        btnColor: "#f38f2d",
        color: "#fff",
        imgUrl: "05.png",
    }, {
        ID: "06",
        Name: "高压泵",
        NameEn: "HP Waterjet Pumps",
        btnColor: "#c0a0ff",
        color: "#fff",
        imgUrl: "06.png",
    }, {
        ID: "07",
        Name: "服务",
        NameEn: "Service",
        btnColor: "#c0a0ff",
        color: "#fff",
        imgUrl: "07.png",
    }, {
        ID: "08",
        Name: "信息",
        NameEn: "General Information",
        btnColor: "#c0a0ff",
        color: "#fff",
        imgUrl: "08.png",
    }],
    InfoListJson: [{
            ListID: "01",
            ID: "0101",
            Name: "转接头",
            NameEn: "Fittings",
            List: [
                { ID: "010101", Name: "直通", NameEn: "Straight-Coupling", pageNumber: 1 },
                { ID: "010102", Name: "弯通", NameEn: "Elbow", pageNumber: 1 },
                { ID: "010103", Name: "三通", NameEn: "Tee", pageNumber: 1 },
                { ID: "010104", Name: "四通", NameEn: "Cross", pageNumber: 1 }
            ]
        }, {
            ListID: "01",
            ID: "0102",
            Name: "接头",
            NameEn: "Flugs",
            List: [{ ID: "010201", Name: "接头 母", NameEn: "Plug-f", pageNumber: 1 },
                { ID: "010202", Name: "接头 公", NameEn: "Plug-m", pageNumber: 1 }
            ]
        }, {
            ListID: "01",
            ID: "0103",
            Name: "大小头转接",
            NameEn: "Reducers",
            List: [
                { ID: "010301", Name: "双头转接", NameEn: "Double-Nipple", pageNumber: 1 },
                { ID: "010302", Name: "可调节双头转接", NameEn: "Adj.Double-Nipple", pageNumber: 1 },
                { ID: "010303", Name: "母转公", NameEn: "Adapter_fm", pageNumber: 1 },
                { ID: "010304", Name: "转换转接 母-母", NameEn: "Reducer-Coupling-ff", pageNumber: 1 },
                { ID: "010305", Name: "转换转接 公-公", NameEn: "Reducer-Coupling-mm", pageNumber: 1 },
                { ID: "010306", Name: "转换转接 公-母", NameEn: "Reducer-Coupling_mf", pageNumber: 1 }
            ]
        },
        {
            ListID: "01",
            ID: "0104",
            Name: "高压阀",
            NameEn: "HP Valves",
        },
        {
            ListID: "01",
            ID: "010400",
            Name: "手阀",
            NameEn: "Hand Valves",
            List: [
                { ID: "010401", Name: "直通手阀", NameEn: "Hand-valve-straight", pageNumber: 2 },
                { ID: "010402", Name: "弯通手阀", NameEn: "Hand-valve-angle", pageNumber: 2 },
                { ID: "010403", Name: "三通手阀", NameEn: "Hand-valve-3way", pageNumber: 2 },
                { ID: "010404", Name: "三通双手阀", NameEn: "Hand-valve-3way2stem", pageNumber: 2 }
            ]
        }, {
            ListID: "01",
            ID: "010440",
            Name: "气动阀",
            NameEn: "Pneumatic Valve",
            List: [
                { ID: "010441", Name: "直通气动阀 2.0", NameEn: "Pneum.valve-straight 2.0", pageNumber: 2 },
                { ID: "010442", Name: "弯通气动阀 2.0", NameEn: "Pneum.valve-angle 2.0", pageNumber: 2 },
                { ID: "010443", Name: "三通气动阀 2.0", NameEn: "Pneum.valve-3way 2.0", pageNumber: 2 },
                { ID: "010444", Name: "三通双气动阀 2.0", NameEn: "Pneum.valve-3way2stem 2.0", pageNumber: 2 },
                { ID: "010445", Name: "直通气动阀 NC2.0", NameEn: "Pneum.valve-straight-NC2.0", pageNumber: 2 },
                { ID: "010446", Name: "弯通气动阀 NC2.0", NameEn: "Pneum.valve-angle-NC2.0", pageNumber: 2 },
                { ID: "010447", Name: "三通气动阀 NC2.0", NameEn: "Pneum.valve-3way-NC2.0", pageNumber: 2 },
                { ID: "010448", Name: "三通双气动阀 NC2.0", NameEn: "Pneum.valve-3way2stem-NC2.0", pageNumber: 2 }
            ]
        }, {
            ListID: "01",
            ID: "0105",
            Name: "高压管",
            NameEn: "HPTubes",
            List: [
                { ID: "010501", Name: "高压管", NameEn: "Hptube", pageNumber: 2 },
                { ID: "010502", Name: "增强高压管", NameEn: "Autofrettage", pageNumber: 1 }
            ]
        }, {
            ListID: "01",
            ID: "0106",
            Name: "工具",
            NameEn: "Tools",
            List: [
                { ID: "010601", Name: "锥面工具", NameEn: "Coning-tool", pageNumber: 1 },
                { ID: "010602", Name: "螺纹工具", NameEn: "Threading-tool", pageNumber: 1 },
                { ID: "010603", Name: "阀工具箱", NameEn: "Toolkit-valve", pageNumber: 1 }
            ]
        },
        {
            ListID: "02",
            ID: "0201",
            Name: "纯水切割头",
            NameEn: "Pure Water",
            List: [
                { ID: "020101", Name: "TypeX-PWJ", NameEn: "", pageNumber: 2 },
                { ID: "020102", Name: "TypeV2.0-PWJ", NameEn: "", pageNumber: 2 },
                { ID: "020103", Name: "TypeIV2.0-PWJ", NameEn: "", pageNumber: 2 },
                { ID: "020105", Name: "TypeXIII-PWJ", NameEn: "", pageNumber: 2 },

                { ID: "020121", Name: "Type V2.0- AR-908000", NameEn: "Type V2.0- AR-908000", pageNumber: 2 },
                { ID: "020122", Name: "Type V2.0- AR-908001", NameEn: "Type V2.0- AR-908001", pageNumber: 2 },
                { ID: "020123", Name: "Type V2.0-908009", NameEn: "Type V2.0-908009", pageNumber: 2 },
                { ID: "020124", Name: "Type V2.0-908011", NameEn: "Type V2.0-908011", pageNumber: 2 },
                { ID: "020125", Name: "食品切割头", NameEn: "Type IV2.0-FOOD SAFE", pageNumber: 2 },
            ]
        }, {
            ListID: "02",
            ID: "0202",
            Name: "加砂切割头",
            NameEn: "Abrasive",
            List: [
                { ID: "020201", Name: "TypeV2.0-AWJ", NameEn: "TypeV2.0-AWJ", pageNumber: 1 },
                { ID: "020202", Name: "TypeIV2.0-AWJ", NameEn: "TypeIV2.0-AWJ", pageNumber: 1 },
                { ID: "020203", Name: "加砂刀头", NameEn: "Abrasive", pageNumber: 1 }
            ]
        },
        { ListID: "02", ID: "0203", Name: "切割头刀杆", NameEn: "Collimation Tubes" },
        {
            ListID: "02",
            ID: "0204",
            Name: "切割头工具",
            NameEn: "Tools",
            List: [{ ID: "020401", Name: "切割头维护工具箱", NameEn: "Toolkit-cuttinghead", pageNumber: 1 }]
        },
        {
            ListID: "03",
            ID: "0301",
            Name: "磨料输送器",
            NameEn: "Abrasive Feeder",
            List: [{ ID: "030101", Name: "送砂器", NameEn: "Abrasive-Feeder", pageNumber: 2 }]
        }, {
            ListID: "03",
            ID: "0302",
            Name: "磨料输送系统",
            NameEn: "Abrasive Conveyor System",
            List: [
                { ID: "030201", Name: "储砂罐 100Kg", NameEn: "Hopper100", pageNumber: 2 },
                { ID: "030202", Name: "储砂罐 1300Kg", NameEn: "Hopper1300", pageNumber: 2 }
            ]
        },
        {
            ListID: "04",
            ID: "04010",
            Name: "旋转接头",
            NameEn: "Swivel Joints",
            List: [
                { ID: "040101", Name: "旋转接头 直接", NameEn: "Swivel-straight", pageNumber: 2 },
                { ID: "040102", Name: "旋转接头 90°", NameEn: "Swivel-90", pageNumber: 2 },
                { ID: "040103", Name: "平行旋转接头", NameEn: "Swivel joint Parallel 2.0", pageNumber: 2 },
                { ID: "040104", Name: "含盘管旋转手臂", NameEn: "Swivel joint incl Hp coil", pageNumber: 2 }
            ]
        }, {
            ListID: "04",
            ID: "04020",
            Name: "盘管",
            NameEn: "Coils",
            List: [{ ID: "040201", Name: "高压盘管 管道", NameEn: "HP coils ", pageNumber: 2 }, ]
        }, {
            ListID: "04",
            ID: "04030",
            Name: "高压过滤器",
            NameEn: "HP Filters",
            List: [
                { ID: "040301", Name: "1/4\"直接过滤器", NameEn: "14-straight 2.0", pageNumber: 2 },
                { ID: "040302", Name: "1/4\" 90°过滤器", NameEn: "14-90degree 2.0", pageNumber: 2 },
                { ID: "040303", Name: "9/16\" 90°过滤器", NameEn: "916-90degree 2.0", pageNumber: 2 },
                { ID: "040304", Name: "迷你过滤器", NameEn: "Filter straight mini 2.0", pageNumber: 2 }
            ]
        }, {
            ListID: "04",
            ID: "04035",
            Name: "泄压阀",
            NameEn: "Pressure Relief Valve",
            List: [{ ID: "040351", Name: "泄压阀", NameEn: "Pressure-Relief", pageNumber: 2 }]
        }, {
            ListID: "04",
            ID: "04040",
            Name: "碰撞保护",
            NameEn: "Collision Protection"
        }, {
            ListID: "04",
            ID: "04050",
            Name: "运载系统",
            NameEn: "Carriage Systems"
        }, {
            ListID: "04",
            ID: "04060",
            Name: "自动泄压阀",
            NameEn: "Block&Bleed",
            List: [{ ID: "040601", Name: "自动泄压阀", NameEn: "Bbvalve", pageNumber: 1 }]
        }, {
            ListID: "04",
            ID: "04070",
            Name: "切割头支架",
            NameEn: "Cutting Head Collets"
        }, {
            ListID: "04",
            ID: "04080",
            Name: "蜂窝板",
            NameEn: "Combs",
            List: [{ ID: "040801", Name: "蜂窝板", NameEn: "Comb", pageNumber: 1 }]
        }, {
            ListID: "04",
            ID: "04090",
            Name: "压力计量",
            NameEn: "Manometer",
            List: [{ ID: "040901", Name: "高压表", NameEn: "Gauge", pageNumber: 1 }]
        },
        {
            ListID: "04",
            ID: "04100",
            Name: "压力传感器",
            NameEn: "Pressure Sensors",
            List: [{ ID: "041001", Name: "传感器", NameEn: "Sensor", pageNumber: 1 }]
        }, {
            ListID: "04",
            ID: "04110",
            Name: "工具",
            NameEn: "Tools",
            List: [{ ID: "041101", Name: "维护工具箱", NameEn: "Toolkitcase", pageNumber: 1 },
                { ID: "041102", Name: "设备改造", NameEn: "Retrofit Bystronic ", pageNumber: 1 }
            ]
        },


        {
            ListID: "05",
            ID: "0501",
            Name: "喷嘴",
            NameEn: "Orfices",
            List: [
                { ID: "050101", Name: "90型宝石喷嘴", NameEn: "Orifice_S_Type90", pageNumber: 1 },
                { ID: "050102", Name: "90型钻石喷嘴", NameEn: "Orifice_D_Type90", pageNumber: 2 },
                { ID: "050103", Name: "90型钻石喷嘴", NameEn: "Orifice_ND_Type90 ", pageNumber: 2 },
                { ID: "050104", Name: "90型钻石喷嘴", NameEn: "Orifice_BD_Type90", pageNumber: 2 },


                { ID: "050111", Name: "91型宝石喷嘴", NameEn: "Orifice_S_Type91", pageNumber: 1 },
                { ID: "050112", Name: "91型钻石喷嘴", NameEn: "Orifice_D_Type91", pageNumber: 2 },
                { ID: "050113", Name: "91型纳米金刚石喷嘴", NameEn: "Orifice_ND_Type91", pageNumber: 1 },
                { ID: "050114", Name: "90型纳米金刚石喷嘴", NameEn: "Orifice_BD_Type90", pageNumber: 1 },

                { ID: "050121", Name: "94型宝石喷嘴", NameEn: "Orifice_S_Type94", pageNumber: 1 },
                { ID: "050122", Name: "94型钻石喷嘴", NameEn: "Orifice_D_Type94", pageNumber: 2 },
                { ID: "050123", Name: "94型纳米金刚石喷嘴", NameEn: "Orifice_ND_Type94", pageNumber: 1 },
                { ID: "050126", Name: "94型纳米金刚石喷嘴", NameEn: "Orifice_BD_Type94", pageNumber: 1 }
            ]
        }, {
            ListID: "05",
            ID: "0502",
            Name: "砂管",
            NameEn: "Focusing Tubes",
            List: [{ ID: "050201", Name: "砂管", NameEn: "Focusing-tube", pageNumber: 1 }]
        }, {
            ListID: "05",
            ID: "0503",
            Name: "磨料",
            NameEn: "Abrasive Material",
            List: [{ ID: "050301", Name: "磨料", NameEn: "Abrasive-material", pageNumber: 1 }]
        },

        { ListID: "06", ID: "060102", Name: "高压泵 E", NameEn: "Pump-E", pageNumber: 2 },
        { ListID: "06", ID: "060104", Name: "高压泵配件 E", NameEn: "Pump-E part list", pageNumber: 3 },

        { ListID: "06", ID: "060110", Name: "高压泵升级改造", NameEn: "Retrofit pumps", pageNumber: 3 },
        { ListID: "06", ID: "060201", Name: "高压泵租赁", NameEn: "JETACCESS", pageNumber: 3 },
        { ListID: "06", ID: "060202", Name: "QUANTUM NTX高压泵", NameEn: "QUANTUM NTX Pump", pageNumber: 3 },
        { ListID: "06", ID: "060203", Name: "QUANTUM D高压泵", NameEn: "QUANTUM-D Pump", pageNumber: 3 },
        { ListID: "06", ID: "060204", Name: "QUANTUM VP高压泵", NameEn: "QUANTUM-VP Pump", pageNumber: 3 },
        { ListID: "06", ID: "060205", Name: "Echion 15高压泵", NameEn: "Hyper Echion 15", pageNumber: 3 },
        { ListID: "06", ID: "060206", Name: "Echion 30高压泵", NameEn: "Hyper Echion 30", pageNumber: 3 },
        { ListID: "06", ID: "060207", Name: "Echion 50高压泵", NameEn: "Hyper Echion 50", pageNumber: 3 },


        { ListID: "07", ID: "070101", Name: "维修服务", NameEn: "Service", pageNumber: 2 },
        { ListID: "07", ID: "070102", Name: "ALLFI培训课程", NameEn: "ALLFI Training Program", pageNumber: 1 },
        { ListID: "07", ID: "070104", Name: "个性化配件订制", NameEn: "parts kits", pageNumber: 1 },
        { ListID: "07", ID: "070105", Name: "应用支持", NameEn: "application support", pageNumber: 1 },
        { ListID: "07", ID: "070106", Name: "换算公式", NameEn: "Conv Tables", pageNumber: 1 },
        { ListID: "07", ID: "070107", Name: "应用工具", NameEn: "Application tools", pageNumber: 1 },

        { ListID: "08", ID: "080100", Name: "客户星级", NameEn: "JetSaver", pageNumber: 2 },
        { ListID: "08", ID: "080101", Name: "条款和条件", NameEn: "General terms and conditions", pageNumber: 2 },
        { ListID: "08", ID: "080102", Name: "ALLFI集团概览", NameEn: "ALLFI Group Overview", pageNumber: 1 },
        { ListID: "08", ID: "080103", Name: "安全信息", NameEn: "Safty info", pageNumber: 1 },
        { ListID: "08", ID: "080104", Name: "订购信息", NameEn: "Order Fulfillment", pageNumber: 1 }
    ]
}
export default data